@layer base {
  .wrapper {
  background-color: var(--sk-color-black);
}

.heroPicture {
  display: none;
}

.title {
  margin-bottom: var(--sk-space-40);
  font-size: var(--sk-font-size-7) !important; /* 40px */
  line-height: var(--sk-line-heights-7) !important; /* 48px */
}

.preTitle {
  color: var(--sk-color-green-500);
}

.main {
  --layout-size: 100%;
  position: relative;
  max-width: var(--layout-size);
  padding: var(--sk-space-80) var(--sk-space-16);
  color: var(--sk-color-white);
}

.description {
  font-size: 20px;
  line-height: 28px;
}

@media (--sk-medium-viewport) {
  .main {
    padding: 136px 104px var(--sk-space-64);
    overflow-x: clip;
  }

  .title {
    position: relative;
    z-index: var(--z-up-in-the-current-stacking-context);
    max-width: 520px;
    font-size: var(--sk-font-size-8) !important; /* 48px */
    line-height: var(--sk-line-heights-6) !important; /* 56px */
  }

  .description {
    max-width: 400px;
  }

  .heroPicture {
    display: block;
    position: absolute;
    right: -135px;
    bottom: -25px;
    width: 395px;
    height: 382px;
  }

  .heroPictureShorter {
    right: 45px;
    bottom: -15px;
    width: 286px;
    height: 277px;
  }
}

@media (--sk-large-viewport) {
  .main {
    --layout-size: 77rem;
    width: 100%;
    max-width: var(--layout-size);
    margin: auto;
    padding: 136px 0 var(--sk-space-64);
  }

  .title {
    max-width: 650px;
    padding-left: var(--sk-space-48);
    font-size: 58px !important;
    line-height: var(--sk-line-heights-6) !important; /* 56px */
  }

  .heroPicture {
    position: absolute;
    top: 100px;
    right: 160px;
    width: 528px;
    height: 510px;
  }

  .heroPictureShorter {
    right: 165px;
    bottom: -15px;
    width: 470px;
    height: 370px;
    object-fit: cover;
    object-position: left top;
    border-radius: 14px;
  }

  .description {
    padding-left: var(--sk-space-48);
    font-size: 24px;
    line-height: 32px;
  }
}

}