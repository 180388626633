@layer base {
  .contentBoosts {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

.link {
  display: block;
  text-decoration: none;
}

.link:focus-visible {
  border-radius: var(--sk-data-display-card-radius);
  outline: none;
  background: transparent;
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-200);
}

}